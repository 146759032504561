<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
        <div class="center-content-v3">
            <div class="container-permission-access">
                <span class="title-permission-access">You need permission to access</span>
                <span class="sub-permission-access">You’re signed in as <span>{{ emailUser }}</span></span>
                <span class="sub-permission-access"><a href="/logout">Sign Out</a></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";

export default {
    components: {
        Navbar
    },
    computed: {
        emailUser() {
            return this.$store.getters['user/email']
        }
    }
}
</script>

<style>
.container-permission-access{
    width: 426px;
    background-color: #FFFFFF;
    height: 222px;
    padding: 32px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title-permission-access{
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: -0.04em;
    color: #262A2C;
    margin-bottom: 32px;
}
.sub-permission-access{
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #919394;
    margin-bottom: 40px;

}
.sub-permission-access span{
    color: #262A2C;
}
.sub-permission-access a{
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #00AAFF;
}
</style>